body {
  
  background-image: url("/src/img/background.jpg");
  background-size: cover;
  
  padding: 0;
  width: 100%;
  height: 100%;
  margin: 0px;
  
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

html{
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  scroll-behavior: smooth;

}
html, body {
  overflow-x: hidden;
  
}

h1,h2,h3, p, a ,li{
  font-family: 'Roboto', sans-serif;
  color: white;
}
